<!-- Mto particular de Expedientes --    X2G0WQ  -->
 
<template>
  <div  v-if="schema"
        style="align-self:flex-start">
     <v-sheet :elevation="4"  >
       
       <!-- Cabecera -->
      <div class="cabecera">
        <base_Header
          v-if="Entorno.header.header"          
          :Entorno="Entorno.header"         
          @onEvent="event_Header">
        </base_Header>
      </div> 
     
      <!-- Botones Mto -->
      <div class="py-2 conflex" style="width:900px;">
        
        <!-- Mto -->            
        <btramto 
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="m"
            :estado="estado"
            @onEvent="event_btra_Mto">        
        </btramto>

        <div class="conflex" style="margin-left:100px;justify-content:space-between;width:20%">
          <!-- Ventana de Documentos -->
          <div v-if="record">
            <exp_Docs
              :padre="stName"
              :id="record.id"
              :show="schema.btra.extra.docs">          
            </exp_Docs>
          </div>

          <!-- Ventana de Comunicados -->
          <div v-if="record">
            <exp_Comunicados
              :padre="stName"
              :id="record.id"
              :accionRow="record"
              :show="schema.btra.extra.comunicados && app=='1'">          
            </exp_Comunicados>
          </div>

          <!-- Ventana de Comunicados -->
          <div>
            <exp_Incidencias
              :padre="stName"
              :id="record.id"
              :accionRow="record"
              :show="app==1">              
            </exp_Incidencias>
          </div>

          <!-- LLamadas Softphone -->
          <v-btn            
            v-bind="$cfg.btra_cfg.standard"            
            title='Llamadas'
            @click="showLlamadas">            
              <v-icon>{{ "mdi-phone" }}</v-icon>
          </v-btn>   
                   
          <!-- Extra -->            
          <btraextra
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="standard"
              @onEvent="event_btra_Extra">     
          </btraextra>      
        </div>      
      </div>

      <div class="conflexnow blue-grey lighten-2"
           style="height:65px;padding:10px">

          <div style="font-weight:bold;font-size:1.5rem;color:#B71C1C">
            {{ schema.ctrls.control.value }}         
          </div>
          
           <div class="white--text title pl-3">
            {{ schema.ctrls.fallename.value }}
          </div>

          <v-checkbox style="width:80px" class="pl-2 py-3"
            dense
            v-bind="$checkbox"
            v-model="schema.ctrls.nulo.value"
            :label="schema.ctrls.nulo.label"
            :disabled="!is_edit">              
          </v-checkbox>         
            
           
          <!-- <div class="title" style="color:#B71C1C">
            {{ schema.ctrls.control.value }}
          </div> -->
          <!-- <div class="white--text title pl-2">
            {{ schema.ctrls.fallename.value }}
          </div> -->
          
          <div class="conflexnow pl-1" style="width:450px">
              <v-icon small color='red accent-4'> mdi-alert </v-icon>
              <vlinput style="flex:1 1 300px"
                    stilot="color:white;background-color:#78909C"
                    v-model="schema.ctrls.notice.value"
                    :disabled="!is_edit">
              </vlinput>                 
              <vlinput  style="flex:1 1 300px;margin-left:5px"
                        stilot="color:white;background-color:#78909C"
                        v-model="schema.ctrls.notice_1.value"
                        :disabled="!is_edit">
              </vlinput>
          </div>

            <div class="columna white--text pl-2">
              <div class="conflex">{{ schema.ctrls.op.value }}</div>
              <div class="conflex">{{ schema.ctrls.fhhr.value }}</div>
            </div>

             <div style="margin-left:20px;margin-right:10px">
              <v-checkbox
                  color='yellow'
                  v-bind="$checkbox"
                  v-model="schema.ctrls.wait.value"
                  :label="schema.ctrls.wait.label"
                  :disabled="!is_edit">
              </v-checkbox>
              <span style="color:silver;">{{ accionRow.id }}</span>
              
             </div> 
        </div>
       </v-sheet>
      
      
      <ZexpPhone               
        :syncDisparo="disparoLlamadas"              
        :recordAux="{ id:record.id }">
      </ZexpPhone>
       

       <!-- tab menu -->
       <v-tabs style="margin-top:1px"
              v-model="tab"
              background-color="blue-grey darken-1"
              active-class="blue-grey lighten-3 font-weight-bold"
              :align-with-title="true"
              dark
              show-arrows
              hide-slider
              height="30px">
            
            <v-tab v-for="seccion in secciones" :key="seccion.titulo" @click="clickTab(seccion)">
                  <v-icon class="pr-2">{{ seccion.icono }}</v-icon>
                  <div class="white--text">{{ seccion.titulo }}</div>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">          
          <v-tab-item
            v-for="seccion in secciones"
            :key="seccion.titulo"
            :transition="false"
            :reverse-transition="false">                        
            <div >
              <!-- añado propiedad recordAux para el finder de presup de la nueva versión -->
              <component 
                v-if="$store.state[stName].record"

                syncAccion='buscar'
                :syncDisparo="disparoFinder"
                :recordAux="$store.state[stName].record || {}"


                :is="seccion.comp"  
                :padre="stName"              
                :id="$store.state[stName].record.id"
                :disparoFinder="disparoFinder"
                :disparoTab="disparoTab"
                @onEvent="event_btra_Mto">
              </component>
             </div>
          </v-tab-item>
       </v-tabs-items>        

       <component
          v-if="$store.state[stName].record"
          :expRecord="$store.state[stName].record"
          :facturas="this.$store.state[stName].recordsDetalle[2]"
          :opcion="cierreApertura_opcion"
          :is="component_cierreApertura"
          @event_cierreApertura="event_cierreApertura">        
        </component>      
  </div>
</template>


<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import { mixinAvisos } from "@/mixins/mixinAvisos.js"
  import ZexpPhone from "@/components/ZexpPhone";
  import expPresF from "@/components/expPresF";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
  const exp_M_llamada = () => plugs.groute("exp_M_llamada.vue", "comp");
  const exp_M_servicio = () => plugs.groute("exp_M_servicio.vue", "comp");
  const exp_M_sepelio = () => plugs.groute("exp_M_sepelio.vue", "comp");
  const exp_M_traslado = () => plugs.groute("exp_M_traslado.vue", "comp");
  /* const exp_M_presup = () => plugs.groute("exp_M_presup.vue", "comp"); */
  const exp_M_incidencias = () => plugs.groute("exp_M_incidencias.vue", "comp");
  const exp_M_ciasfun = () => plugs.groute("exp_M_ciasfun.vue", "comp");
  const exp_M_res = () => plugs.groute("exp_M_res.vue", "comp");
  const exp_Docs = () => plugs.groute("exp_Docs.vue", "comp");
  const exp_Comunicados = () => plugs.groute("exp_Comunicados.vue", "comp");
  const exp_Incidencias = () => plugs.groute("exp_Incidencias.vue", "comp");
  const exp_cierreApertura = () => plugs.groute("exp_cierreApertura.vue", "comp");

  export default {
    mixins: [mixinMto, mixinAvisos],
    components: {
      base_Header,
      btramto,
      btraextra,
      exp_M_llamada,
      exp_M_servicio,
      exp_M_sepelio,
      exp_M_traslado,
      expPresF,
      exp_M_incidencias,
      exp_M_ciasfun,
      exp_M_res,
      exp_Docs,
      exp_Comunicados,
      exp_Incidencias,
      exp_cierreApertura,
      ZexpPhone
    },

    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: null},
      disparo: { type:Boolean, default:false },      
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {        
        schema: null,
        api:'exp_M',
        stName:'stMexp',
        disparoFinder:false,        
        mtos_auxiliares: [
          { store:'stMexp_Tras_M', msg:'Tiene un traslado en edición. Guarde o Cancele antes de continuar'},
           { store:'stMexp_Ciasfun_M', msg:'Tiene un Cias/Fun en edición. Guarde o Cancele antes de continuar'},
        ],
        
        secciones: [
          {
            titulo: "Llamada",
            icono: "mdi-phone",
            comp: "exp_M_llamada"
          },

          {
            titulo: "Servicio",
            icono: "mdi-briefcase-outline",
            comp: "exp_M_servicio"
          },
          {
            titulo: "Incidencias",
            icono: "mdi-alert",
            comp: "exp_M_incidencias"
          },
          {
            titulo: "Sepelio",
            icono: "mdi-church",
            comp: "exp_M_sepelio"
          },

          {
            titulo: "Traslado",
            icono: "mdi-airplane",
            comp: "exp_M_traslado"
          },

          {
            titulo: "Presup.",
            icono: "mdi-calculator-variant",
            //comp: "exp_M_presup"
            comp: "expPresF"
          },

           {
             titulo: "Cias Fun",
             icono: "mdi-function",
             comp: "exp_M_ciasfun"
           },

          {
            titulo: "Res CS",
            icono: "mdi-alert",
            comp: "exp_M_res"
          }
        ],
        tab: null,
        modal:false,
        disparoTab:false,
        component_cierreApertura: null,
        cierreApertura_opcion:'',
        disparoLlamadas:false
      };
    },

   
    methods: {
      ini_data(){
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  

        // entorno
        this.Entorno.header.titulo = "Expedientes";

        // if (typeof this.Entorno.btra == "string") this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo[this.Entorno.btra]));
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));
        
        // desactivo borrar expediente
        this.btnSet(3, { disabled:true });
        
        // this.Entorno.header.style += ";min-width:60rem";
        console.log(" dev ******E N T O R N O**************** exp_M",this.Entorno);
      },


      ini_schemaCtrls(){
        //this.btnSet(1,{view:true, disabled:true});
        //this.btnSet(2,{view:true, disabled:true});
        console.log("DEV " + this.api + " ********************* (ini_schemaCtrls)");
        this.component_base="base_M";
      },

      
      // CIAS PREDETERMINADAS
      MAPFRE() {  return ( this.schema.ctrls.callemp_tp.value==2 && this.schema.ctrls.callemp.value== 83) },
      MAPFRE_ASIS() {  return ( this.schema.ctrls.callemp_tp.value==2 && this.schema.ctrls.callemp.value== 84) },
      KUTXABANK() {  return ( this.schema.ctrls.callemp_tp.value==2 && this.schema.ctrls.callemp.value== 53258) },     
      /* function GENERALI()   :Boolean {  return (Asoc.find_field("callemp_tp").ctl==2 && Asoc.find_field("callemp").ctl==53204)}
      function CAJAMAR()    :Boolean {  return (Asoc.find_field("callemp_tp").ctl==2 && Asoc.find_field("callemp").ctl==53231)} */

      APD_asistencia() {
				if (this.schema.ctrls.callemp_tp.value!=2 || this.schema.ctrls.callemp.value==0) return false;
        //if (_G.pick_DATAcias( Asoc.find_field("callemp").ctl,1)!=0) return true;
        if (this.MAPFRE() || this.KUTXABANK()) return true;
				return false;
      },


      // gancho antes de guardar, ya sea un nuevo como un editar
      guardar_before() {
        if (this.schema.ctrls.op.value== "" || this.schema.ctrls.op.value.charAt(0)=="*") {
          this.schema.ctrls.op.value= this.usu;
        }
        
        return true;
      },

      //datos omision  
      nuevoFin() {
        //fecha de fallecimiento
        this.schema.ctrls.fallefhhr.value=this.actualDateTime();

      },


      // antes de llamar a la API para guardar los datos recibidos como propiedad
      before_guardar_Api(data) {        
        data.changes['control']= this.schema.ctrls.control.value;
      },

      cset_zona_origen({ ctrl, schema, record_aux }) {
         //--------- EXCEPCIONES MAPFRE ASISTENCIA
         // ASIGNAR ZONA_ORIGEN (falleenloc si es en españa, si no lo es, ver falledestloc)
         //( 062=Pais España )
         if (schema.falleenloc.value!="" && schema.falleenloc.value.substr(0,3)=="062") {
            record_aux[ctrl.f]=
            this.$store.getters.get_provZona(schema.falleenloc.value);
            return;
         }
         if (schema.falledestloc.value!="" && schema.falledestloc.value.substr(0,3)=="062") {
            record_aux[ctrl.f]=this.$store.getters.get_provZona(schema.falledestloc.value);
            return;
         }
       },


      /* cset_zona_origen({ ctrl, schema }) {

        //--------- EXCEPCIONES MAPFRE ASISTENCIA
        if (this.MAPFRE_ASIS()) {         
          ctrl.value= "0";
          return;
        }
        // comprobar si tiene asistencia APD
        if (!this.APD_asistencia()) {        
          ctrl.value= "0";
          return;
        }

        // ASIGNAR ZONA (Asegurar que es dentro de España el origen y si no lo es poner la zona de destino)				( 062=Pais España )
        if (schema.falleenloc.value!="" && schema.falleenloc.value.substr(0,3)=="062") {        
          ctrl.value= this.$store.getters.get_provZona(schema.falleenloc.value);
          return;
        }
        if (schema.falledestloc.value!="" && schema.falledestloc.value.substr(0,3)=="062") {          
          ctrl.value= this.$store.getters.get_provZona(schema.falledestloc.value);        
          return;
        }

        ctrl.value= "0";
      },
 */
      // evento que viene de exp_M_incidencias
      confirmar_incidencia(evt) {
        this.$root.$alert
        .open(evt.pregunta, "confirm")
        .then(r => {
          if (r) { this[evt.fnOk](evt); return }
          this[evt.fnCancel](evt);
        });
      },


      // evento que viene de exp_M_incidencias    
      borrar_incidencia(evt) {       
        this.$root.$alert
        .open(evt.pregunta, "confirm")
        .then(r => {
          if (r) { this[evt.fnOk](evt); return }   
          this[evt.fnCancel](evt);
        });
      },


      // confirmación capital insuficiente (exp_M_incidencias)
      async confirmacion_insuf(evt) {
        // guardo motivo insuficiencia capital en observaciones Insuficiencia Capital        
        let motivo= evt.motivos.find(elem=> elem.id==this.schema.ctrls.motivo_insufcapital.value);        
        this.schema.ctrls.chkautoriza_obs.comp.valueaux= (" SOLICITA: " + motivo.name + "\n") + this.schema.ctrls.chkautoriza_obs.comp.valueaux;
        
        // grabo Mto
        let r= await this.guardar();        
        if (!r) return;

        // envio SMS a JZ
        let apiResult= await this.$store.dispatch("envio_SMS", { mov:"J" + this.schema.ctrls.zona_apd1.value, txt:'SOLICITUD CAPITAL INSUFICIENTE EXP: ' + this.schema.ctrls.control.value + '. Motivo: ' + motivo.name });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
      },

      // cancelo pregunta confirmación capital insuficiente
      cancelo_confimacion_insuf() {
        this.schema.ctrls.motivo_insufcapital.value= this.record['motivo_insufcapital'];
        this.schema.ctrls.chkcapital.value= "0";
      },

      
      // eliminación capital insuficiente (exp_M_incidencias)
      async eliminacion_insuf() {
        // guardo msg de cancelación de insuficiencia capital en observaciones Insuficiencia Capital
        this.schema.ctrls.chkautoriza_obs.comp.valueaux= (" CANCELADO CHECK CAPITAL INSUFICIENTE.\n") + this.schema.ctrls.chkautoriza_obs.comp.valueaux;
        
        // quito selección combo motivo insuficiencia capital
        this.schema.ctrls.motivo_insufcapital.value= "0";

        // grabo Mto
        let r= await this.guardar();
        if (!r) return;
       
        // envio SMS a JZ
        let apiResult= await this.$store.dispatch("envio_SMS", { mov:"J" + this.schema.ctrls.zona_apd1.value, txt:'CANCELADO SOLICITUD CAPITAL INSUFICIENTE EXP: ' + this.schema.ctrls.control.value});
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
      },

      // cancelo pregunta eliminación capital insuficiente
      cancelo_eliminacion_insuf() {
        this.schema.ctrls.chkcapital.value= "1";
      },


      // confirmación posible ocultación (exp_M_incidencias)
      async confirmacion_pOcultacion() {
        this.schema.ctrls.chkocultacionobs.comp.valueaux= (" CHECK POSIBLE OCULTACIÓN.\n") + this.schema.ctrls.chkocultacionobs.comp.valueaux;

        
        // grabo Mto
        let r= await this.guardar();        
        if (!r) return;

        // envio SMS a JZ
        let apiResult= await this.$store.dispatch("envio_SMS", { mov:"J" + this.schema.ctrls.zona_apd1.value, txt:'SOLICITUD DE POSIBLE OCULTACIÓN. EXP: ' + this.schema.ctrls.control.value });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
      },

      // cancelo pregunta confirmación posible ocultación
      cancelo_confirmacion_pOcultacion() {
        this.schema.ctrls.chkocultacion_p.value= "0";
      },


      // eliminación posible ocultación (exp_M_incidencias)
      async eliminacion_pOcultacion() {
        // guardo msg de cancelación de posible ocultación en observaciones ocultación
        this.schema.ctrls.chkocultacionobs.comp.valueaux= ("  CANCELADO CHECK POSIBLE OCULTACIÓN.\n") + this.schema.ctrls.chkocultacionobs.comp.valueaux;

        // grabo Mto
        let r= await this.guardar();
        if (!r) return;

        // envio SMS a JZ
        let apiResult= await this.$store.dispatch("envio_SMS", { mov:"J" + this.schema.ctrls.zona_apd1.value, txt:'CANCELADO SOLICITUD DE POSIBLE OCULTACIÓN. EXP: ' + this.schema.ctrls.control.value });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
      },

      // cancelo pregunta eliminación posible ocultación
      cancelo_eliminacion_pOcultacion() {
        this.schema.ctrls.chkocultacion_p.value= "1";
      },


      // confirmación ocultación (exp_M_incidencias)
      async confirmacion_ocultacion() {
        this.schema.ctrls.chkocultacionobs.comp.valueaux= ("  CHECK OCULTACIÓN.\n") + this.schema.ctrls.chkocultacionobs.comp.valueaux;
        
        // grabo Mto
        let r= await this.guardar();        
        if (!r) return;

        // envio SMS a JZ
        let apiResult= await this.$store.dispatch("envio_SMS", { mov:"J" + this.schema.ctrls.zona_apd1.value, txt:'SOLICITUD DE OCULTACIÓN. EXP: ' + this.schema.ctrls.control.value });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
      },

      // cancelo pregunta confirmación ocultación
      cancelo_confirmacion_ocultacion() {
        this.schema.ctrls.chkocultacion.value= "0";
      },


      // eliminación ocultación (exp_M_incidencias)
      async eliminacion_ocultacion() {
        // guardo msg de cancelación de posible ocultación en observaciones ocultación
        this.schema.ctrls.chkocultacionobs.comp.valueaux= ("   CANCELADO CHECK OCULTACIÓN.\n") + this.schema.ctrls.chkocultacionobs.comp.valueaux;
      
        // grabo Mto
        let r= await this.guardar();
        if (!r) return;

        // envio SMS a JZ
        let apiResult= await this.$store.dispatch("envio_SMS", { mov:"J" + this.schema.ctrls.zona_apd1.value, txt:'CANCELADO SOLICITUD DE OCULTACIÓN. EXP: ' + this.schema.ctrls.control.value });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
      },

      // cancelo pregunta eliminación ocultación
      cancelo_eliminacion_ocultacion() {
        this.schema.ctrls.chkocultacion.value= "1";
      },


      // entra al pulsar en cualquier pestaña del Tab
      // si se pulsa en la pestaña RES CS, se recalcula los datos de compras de esa pestaña
      clickTab(seccion) {    
        if (seccion.titulo=="Res CS") this.disparoTab= !this.disparoTab;
      },

      
      /// CIERRES
      event_cierreApertura(evt) {
        console.log("evento_cierre",evt)
        this.component_cierreApertura =null;
        if (evt.obs=='') { this.btra_Mto({ accion:0 }); return }
        
        if (this.app==1) this.schema.ctrls.chkdef.comp.valueaux=evt.obs;
        if (this.app==2) this.schema.ctrls.obs3_incidencias.comp.valueaux=evt.obs;          
      
        this.btra_Mto({ accion: 4 });
        console.log("evento_cierre",this.schema.ctrls)        
      },

      editar_before() {
        if ((this.app==1 && this.schema.ctrls.chkconfcierre.value>'0') || ( this.app==2 && this.schema.ctrls.chkconf24.value>'0')){
            this.cierreApertura_opcion="aviso"
            this.component_cierreApertura = "exp_cierreApertura";
        }
        return true;
      },


      // muestra ventana de llamadas softphone
      showLlamadas() {
        this.disparoLlamadas= !this.disparoLlamadas;
      },


    },

  };
</script>

